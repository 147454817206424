import React from "react";
import TaxiNeumuster from "../../images/taxi-neumuster.png";
import FeaturedImage from "../../images/travel-easy.png";
import { Link } from "gatsby";
import { Tooltip, Breadcrumbs } from "@material-ui/core";
import "./style.css";

const BlogDetailPage = () => {
  return (
    <div>
      <div className="blog-image">
        <div className="blog-heading">Blog</div>
        <div className="breadcrumbs-section">
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/" className="breadcrumb-link">
              Home
            </Link>
            <Link
              to="/blog/travel-easy-and-always-be-on-time-with-pre-booking-taxi"
              className="breadcrumb-link"
            >
              Taxi Service to Kiel
            </Link>
          </Breadcrumbs>
        </div>
      </div>
      <div className="main-container">
        <h1 className="text-center">
          Travel Easy And Always Be On Time With Pre-booking Taxi
        </h1>
        <br />
        <img src={FeaturedImage} alt="Travel Easy" className="featured-image" />
        <p className="text-justify">
          The flights are already a very tiring and exhausting affair; ideally,
          you should not have to look for a balanced taxi deal with something
          which is both affordable and comfortable. While looking for a{" "}
          <b>
            <Tooltip
              title="taxi to Kiel from Hamburg airport"
              placement="bottom-center"
            >
              <a href="https://www.schnelleintaxi.de/airport-transportation">
                taxi to Kiel from Hamburg airport
              </a>
            </Tooltip>
          </b>
          ,you might end up feeling frustrated after a long flight. Ideally, it
          would help if you did not have to.
        </p>
        <div className="text-justify">
          The world we live in has become so technologically advanced that there
          are very few things that have been thought but unachieved. To save
          yourself from the hassle of finding a  taxi to Kiel from Hamburg
          airport you can pre-book it and travel to the airport in a relatively
          easygoing manner. 
        </div>
        <br />
        <div className="text-justify">
          Pre-booking is a concept that allows the user to book a taxi
          beforehand. It gives you the liberty to board the taxi and head
          towards your actual destination after getting off the flight because
          the airport is rarely someone’s preferred destination after a flight.
          It does not only save a ton of time but also keeps you from the hassle
          of finding the right taxi, which ticks all the boxes of your
          requirement. 
        </div>
        <br />
        <div className="text-justify">
          If your case is the other way around and you are looking 
          <b>
            <Tooltip
              title="taxi from Kiel to Hamburg Airport"
              placement="bottom-center"
            >
              <a href="https://www.schnelleintaxi.de/about">
                taxi from Kiel to Hamburg Airport
              </a>
            </Tooltip>
          </b>
          , then also pre-booking a taxi is always a better option. The
          last-minute booking of an instant taxi will only leave you anxious and
          as a result, even ruin the entire flight.
        </div>
        <br />
        <Tooltip title="Taxi hamburg Airport" placement="bottom">
          <img src={TaxiNeumuster} alt="Taxi Neumuster" className="w-100" />
        </Tooltip>
        <br />
        <br />
        <div className="text-justify">
          Regardless of your requirement, if you are on the outlook for{" "}
          <b>
            <Tooltip title="taxi services to Kiel" placement="center">
              <a href="https://www.schnelleintaxi.de/">
                taxi services to Kiel 
              </a>
            </Tooltip>
          </b>
          SchnelleinTaxi is the answer to your every requirement. We not only
          provide the safest and comfortable rides, but we also take away your
          anxiety about booking the taxi at the very last minute with our
          pre-booking option.
        </div>
        <br />
        <div className="text-justify">
          Our rides are much more than an average can ride from home to the
          airport or the other way around. What we provide is an experience!
          Experience of riding in a classic taxi which is extremely rare in
          modern times. Our skilful drivers are always ready to assist with your
          requirements which also includes lending a hand with your luggage. 
        </div>
        <br />
        <div className="text-justify">SchnelleinTaxi, remember the name!</div>
      </div>

      <div className="main-container bottom-section">
        <h5>
          <b>Categories</b>
        </h5>
        <Link
          to="/blog/travel-easy-and-always-be-on-time-with-pre-booking-taxi"
          className="categories-link"
        >
          <div className="category">Taxi Service To Kiel</div>
        </Link>

        <br />
        <h5>
          <b>Tags</b>
        </h5>
        <div className="tags-container">
          <Link to="/blog" className="categories-link">
            <div className="category">Taxi Hamburg Kiel</div>
          </Link>
          <Link to="/blog" className="categories-link">
            <div className="category">Kiel taxi to Hamburg Airport</div>
          </Link>
          <Link to="/blog" className="categories-link">
            <div className="category">Order Taxi Kiel</div>
          </Link>
          <Link to="/blog" className="categories-link">
            <div className="category">Taxi Services to Kiel</div>
          </Link>
          <Link to="/blog" className="categories-link">
            <div className="category">Kiel Taxi Services</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailPage;
