import React, { useContext } from "react";
import Layout from "../../../components/Layout/layout";
import BlogDetailPage from "../../../components/Blog-Detail/index";
import BlogGermanPage from "../../../components/Blog-Detail/blog_german";
import { LanguageContext } from "../../../lang";
import SEO from "../../../components/SEO/seo";
const BlogDetail = () => {
	const {
		translations: { blog },
	} = useContext(LanguageContext);
	const { culture } = blog;
	return (
		<Layout>
			<SEO
				title="Reisen Sie einfach und seien Sie immer pünktlich mit dem Taxi im Voraus"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="Es ist immer eine bessere Idee, Ihr Taxi im Voraus zu buchen, indem Sie den Taxiservice vor der Buchung nutzen, als in letzter Minute."
			/>
			{culture === "en" ? <BlogDetailPage /> : <BlogGermanPage />}
		</Layout>
	);
};

export default BlogDetail;
